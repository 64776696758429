<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img
          :src="showNewImage2 ? AnswerImg2 : importedImg2"
          alt=""
          class="imgItem2"
        />
      </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg8" :alt="altText" class="imgItem5" />
      </div>
    </div>
    <!-- <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div> -->
    <!-- <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div> -->
    <!-- <div class="imgBox6 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/hipotecariabilletera/components/img/3_1.png";
import importedImg2 from "@/page/hipotecariabilletera/components/img/3_2.png";
import importedImg3 from "@/page/hipotecariabilletera/components/img/3_3.png";
import importedImg4 from "@/page/hipotecariabilletera/components/img/3_4.png";
import importedImg6 from "@/page/kasikvay/components/img/4-1-4.png";
import importedImg7 from "@/page/kasikvay/components/img/4-1-6.png";
import importedImg8 from "@/page/hipotecariabilletera/components/img/1_6.png";
import AnswerImg2 from "@/page/hipotecariabilletera/components/img/3_2_2.png";
import AnswerImg3 from "@/page/hipotecariabilletera/components/img/3_3_3.png";
import AnswerImg4 from "@/page/hipotecariabilletera/components/img/3_4_4.png";
import AnswerImg5 from "@/page/kasikvay/components/img/4-2-4.png";
import AnswerImg6 from "@/page/kasikvay/components/img/4-2-5.png";
import AnswerImg7 from "@/page/kasikvay/components/img/4-2-6.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
       importedImg8: importedImg8,
      AnswerImg2: AnswerImg2,
      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,

      activeImage: 0,
    };
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      //   console.log(this.activeImage);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1,.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}
.imgBox2,
.imgBox3,
.imgBox4,

.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
}

img {
  display: block;
}
.img1,.img5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}
.img2,
.img3,
.img4,

.img6,
.img7 {
  margin-left: 45px;
  margin-right: 45px;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  max-width: 100%;
}
.imgItem4{
  margin-bottom: 120px;
}
.imgItem5{
  max-width: 100%;
  margin-bottom: 100px;
}
</style>