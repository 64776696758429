<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <!-- <img :src="importedImg5" :alt="altText" class="imgItem5" /> -->
        <div class="box1">
          <p class="p1">
            Consejo Nacional para la Protección y Defensa de los Usuarios de
          </p>
          <p class="p2">Servicios Financieros (CONDUSEF)</p>
          <p></p>
          <p class="p3">
            DAE HIPOTECARIA, S.A. DE C.V., SOFOM, E.N.R.® es una entidad
          </p>
          <p>
            financiera seria y que cumple con sus obligaciones legales, por lo
            que es
          </p>
          <p>
            considerada una de las pocas SOFOM ENR actualmente registradas ante
          </p>
          <p>la CONDUSEF. </p>
          <p class="p4">
            Esta información podrá ser consultada y verificada directamente ante
            la
          </p>
          <p>CONDUSEF en un portal que permite a los usuarios de servicios</p>
          <p>
            financieros y al público consultar sobre el estado operativo de
            cualquier
          </p>
          <p>
            SOFOM ENR respecto de sus obligaciones legales.Aquí podrá acceder a
          </p>
          <p>
            dicho portal, donde podrá verificar el estado de funcionamiento DAE
          </p>
          <p>HIPOTECARIA, S.A. DE C.V., SOFOM, E.N.R.®.</p>
        </div>
        <div class="box2">
          <img :src="importedImg7" :alt="altText" class="imgItem7" />
        </div>
        <div class="box3">
          <img :src="importedImg8" :alt="altText" class="imgItem8" />
        </div>
        <div class="box4">
          <p class="p5">Comisión Nacional Bancaria y de Valores (CNBV)</p>
          <p class="p6">
            Como entidad financiera, DAE Hipotecaria, S.A. de C.V., terrorismo.
          </p>
          <p>SOFOM, E.N.R.® cuenta con una opinión técnica otorgada</p>
          <p>por la CNBV que acredita su cumplimiento de medidas para</p>
          <p>prevenir el lavado de dinero y el financiamiento del</p>
          <p class="word-wrap-container">
            terrorismo.
          </p>

          <p class="p8">DAE Hipotecaria, S.A. de C.V., SOFOM, E.N.R.® es una</p>
          <p>entidad financiera que cuenta con un Oficial de Cumplimiento</p>
          <p>en materia de Prevención de Lavado de Activos y</p>
          <p>Financiamiento del Terrorismo certificado por la CNBV.</p>
        </div>
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <img :src="importedImg6" :alt="altText" class="imgItem6" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/hipotecariabilletera/components/img/1_1.png";
import importedImg2 from "@/page/hipotecariabilletera/components/img/1_2.png";
import importedImg3 from "@/page/hipotecariabilletera/components/img/1_3.png";
import importedImg4 from "@/page/hipotecariabilletera/components/img/1_4.png";
import importedImg5 from "@/page/hipotecariabilletera/components/img/1_5.png";
import importedImg6 from "@/page/hipotecariabilletera/components/img/1_6.png";
import importedImg7 from "@/page/hipotecariabilletera/components/img/111.png";
import importedImg8 from "@/page/hipotecariabilletera/components/img/222.png";
export default { 
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1,
.imgBox4,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2,
.imgBox3,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,
.img3,
.img5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2,
.img3,
.img5 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,
.imgItem4,
.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 100px;
}
.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-top: 100px;
  margin-bottom: 100px;
}
.box1 {
  display: inline-block;
  width: 875px;
  height: 520px;
  border-radius: 25px;
  background-color: #1859de;
}
.box2 {
  display: inline-block;
  width: 400px;
  height: 100px;
  border-radius: 10px;
  margin-left: 30px;
}
.box2 > img {
  max-width: 100%;
  margin-top: -290px;
}
.box3 {
  display: inline-block;

  border-radius: 10px;
}
.box3 > img {
  max-width: 90%;
}
.box4 {
  display: inline-block;
  width: 650px;
  height: 500px;
  border-radius: 25px;
  margin-top: -800px;
  margin-left: 700px;
  background-color: #f4f7ff;
}
.p1 {
  margin-top: 50px;
  font-family: Thonburi-Bold;
  font-size: 18px;
  color: #ffffff;
  margin-left: 70px;
  font-weight: 600px;
}
.box1 p {
  font-size: 18px;
  color: #ffffff;
  margin-left: 70px;
}
.p3 {
  margin-top: 50px;
}
.p4 {
  margin-top: 50px;
}
.box1 a {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}
.box4 p {
  margin-left: 70px;
  color: #141414;
}
.p5 {
  margin-top: 50px;
}
.p6 {
  margin-top: 50px;
}
.p8 {
  margin-top: 50px;
}
.box4 a {
  color: #1859de;
  font-weight: bold;
  font-size: 16px;
}
.word-wrap-container {
  word-wrap: break-word; /* 处理英文单词换行 */
  overflow-wrap: break-word; /* 处理非英文内容换行 */
}
</style>
